.page.menu-page {
	padding: 0 vw(40);

	.lightning-bg-icon {
		position: absolute;
		width: vw(1227);
		height: vw(1444);
		left: vw(-227);
		top: vw(-626);
		z-index: -1;
	}

	.header {
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
		margin-top: vw(53);
		margin-bottom: vw(83);
	}

	.buzzr-icon {
		width: vw(455);
		height: auto;
		margin-bottom: vw(35);
	}

	.share-experience {
		font-family: var(--font-family-title);
		font-weight: bold;
		font-size: vw(20);
		line-height: 89%;
		/* or 18px */
		text-align: center;
		text-transform: uppercase;
		margin-bottom: vw(23);
	}

	.share-icons {
		display: flex;
		justify-content: center;
		gap: vw(45);

		svg {
			width: auto;
			height: vw(40);
		}
	}

	.reset-player-btn {
		margin: 0 auto vw(30);
	}

	.body {
		.up-next {
			.title {
				font-family: var(--font-family-title);
				font-weight: bold;
				font-size: vw(24);
				line-height: 89%;
				text-transform: uppercase;
				color: var(--color-text-secondary);
			}
		}

		.show-thumbnail {
			margin-top: vw(26);
			border-radius: vw(20);
			overflow: hidden;

			img {
				width: 100%;
			}
		}
	}

	.links {
		display: flex;
		flex-direction: column;
		gap: vw(30);
		margin-top: vw(60);

		.link {
			display: flex;
			align-items: center;
			font-family: var(--font-family-title);
			font-weight: bold;
			font-size: vw(36);
			line-height: 103%;
			text-transform: uppercase;
			color: var(--color-text-secondary);
			border-bottom: vw(1) solid var(--color-text-secondary);
			padding: vw(12) 0;
			text-decoration: none;

			.chevron-down {
				--color: var(--color-text-secondary);
				margin-left: auto;
				width: vw(24);
				height: auto;
				transform: rotate(-90deg);
			}
		}
	}

	.footer {
		display: flex;
		flex-direction: column;
	}

	.playfab-id {
		font-family: var(--font-family-title);
		font-weight: bold;
		font-size: vw(24);
		line-height: 89%;
		text-transform: uppercase;
		color: var(--color-text-secondary);
		text-align: center;
		margin-bottom: vw(30);
		opacity: 0.25;
	}

	.partners {
		margin-top: vw(50);
		margin-bottom: vw(50);
		display: flex;
		justify-content: space-around;
		align-items: center;

		svg {
			width: auto;
			height: vw(32);
		}

		.fremantle-logo {
			height: vw(27);
		}
	}
	
	&.enter,
	&.appear {
		> .lightning-bg-icon {
			transform: translate(0, -100%);
		}

		.buzzr-icon,
		.share-experience,
		.share-icons > :nth-child(n) {
			opacity: 0;
			transform: translate(0, vw(-50));
		}

		.up-next .title,
		.up-next .show-thumbnail,
		.reset-player-btn,
		.links > :nth-child(n),
		.partners > :nth-child(n) {
			opacity: 0;
			transform: translate(0, vw(50));
		}

		&-active {
			> .lightning-bg-icon {
				transform: translate(0, 0);
				transition: transform 0.6s $easeOutCubic var(--delay, 0s);
			}

			.buzzr-icon,
			.share-experience,
			.share-icons > :nth-child(n),
			.up-next .title,
			.up-next .show-thumbnail,
			.reset-player-btn,
			.links > :nth-child(n),
			.partners > :nth-child(n) {
				opacity: 1;
				transform: translate(0, 0);
				transition: opacity 0.6s $easeOutCubic var(--delay, 0s), transform 0.6s $easeOutCubic var(--delay, 0s);
			}
		}

		>.lightning-bg-icon { --delay: 0s }
		.buzzr-icon { --delay: 0.4s }
		.share-experience { --delay: 0.45s }

		.share-icons {
			@for $i from 1 through 3 {
				> :nth-child(#{$i}) { --delay: #{0.05 * $i + 0.5}s; }
			}
		}

		.up-next .title { --delay: 0.55s }
		.up-next .show-thumbnail { --delay: 0.6s }

		.links {
			@for $i from 1 through 4 {
				> :nth-child(#{$i}) { --delay: #{0.05 * $i + 0.65}s; }
			}
		}

		.partners {
			@for $i from 1 through 4 {
				> :nth-child(#{$i}) { --delay: #{0.05 * $i + 0.8}s; }
			}
		}

		.reset-player-btn { --delay: 1s; }
	}
}