.qr-scan-container {
	position: fixed;
	top: 0;
	left: 0;
	width: 100vw;
	height: calc(var(--vh, 1vh) * 100);
	z-index: 10;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;

	.content {
		height: 100%;
		overflow-x: hidden;
		overflow-y: auto;
		padding: vw(140) vw(86) vw(100);
	}

	&:after {
		content: '';
		position: fixed;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		background: var(--color-overlay-background);
		z-index: -1;
		backdrop-filter: blur(5px);
	}

	.help {
		font-family: var(--font-family-title);
		font-weight: bold;
		font-size: vw(20);
		line-height: 110%;
		text-transform: uppercase;
		margin-bottom: vw(50);
		text-align: center;
	}

	.qr-scan-module {
		position: relative;

		video {
			width: 100%;
			height: 100%;
			object-fit: cover;
		}
	
		.video-ctn {
			position: relative;
			width: 100%;
			height: auto;
			aspect-ratio: 1;
		}
	
		.corner {
			position: absolute;
			width: vw(78);
			height: vw(78);
	
			&.top-left {
				top: vw(-22);
				left: vw(-22);
			}
	
			&.top-right {
				top: vw(-22);
				right: vw(-22);
				transform: scale(-1, 1);
			}
	
			&.bottom-left {
				bottom: vw(-16);
				left: vw(-22);
				transform: scale(1, -1);
			}
	
			&.bottom-right {
				bottom: vw(-16);
				right: vw(-22);
				transform: scale(-1, -1);
			}
		}
	}

	.code-outline-highlight polygon {
		stroke: var(--color-accent-primary);
		stroke-width: vw(15);
	}

	.scan-region-highlight-svg {
		visibility: hidden;
	}

	.error {
		opacity: 0;
		margin-top: vw(60);
		margin-bottom: vw(-50);
		font-family: var(--font-family-title);
		font-weight: bold;
		font-size: vw(20);
		line-height: 1.1;
		text-transform: uppercase;
		text-align: center;
		color: var(--color-accent-primary);
	}

	.select-ctn {
		margin: vw(100) 0 0;
		display: flex;
		justify-content: center;
	}

	.error + .select {
		margin-top: vw(50);
	}

	.close.button {
		position: absolute;
		top: vw(36);
		right: vw(18);
		z-index: 2;
	}
	
	.btn-container {
		position: absolute;
		bottom: 0;
		left: 50%;

		.button {
			transform: translate(-50%, 50%);
		}

		.camera-icon {
			width: vw(56);
		}
	}

	&.enter,
	&.appear {
		opacity: 0;

		.button.close {
			opacity: 0;
			transform: translate(140%, 0);
		}

		.help,
		.select,
		.error,
		.btn-container {
			opacity: 0;
			transform: translate(0, vw(50));
		}

		.qr-scan-module {
			opacity: 0;
			transform: scale(0.5);
		}

		&-active {
			opacity: 1;
			transition: opacity 0.3s $easeOutCubic;

			.help,
			.button.close,
			.select,
			.error,
			.btn-container {
				opacity: 1;
				transform: translate(0, 0);
				transition: opacity 0.6s $easeOutCubic var(--delay, 0s), transform 0.6s $easeOutCubic var(--delay, 0s);
			}

			.qr-scan-module {
				opacity: 1;
				transform: scale(1);
				transition: opacity 0.6s $easeOutCubic var(--delay, 0s), transform 0.6s $easeOutCubic var(--delay, 0s);
			}
		}

		.button.close { --delay: 0.3s }
		.help { --delay: 0.4s }
		.error { --delay: 0.45s }
		.select { --delay: 0.5s }
		.qr-scan-module { --delay: 0.6s }
		.btn-container { --delay: 0.7s }

		&-done {
			.error {
				opacity: 1;
			}
		}
	}
}
