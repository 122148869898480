.notifications-list {
	--speed: 1s;
	--list-width: 100vw;
	--list-padding: #{vw(20)} #{vw(10)};

	position: fixed;
	top: 0;
	left: 0;
	padding: var(--list-padding);
	width: var(--list-width);
	max-height: calc(100vh - var(--margin-top) - var(--margin-bottom));
	overflow: hidden;
	display: flex;
	flex-direction: column;
	align-items: flex-end;
	justify-content: center;
	scroll-behavior: smooth;
	z-index: 9999;
	gap: vw(20);

	pointer-events: none;

	transform-origin: top right;
	transform: scale(var(--app-scale, 1));

	.notification {
		animation: notification-in-left var(--speed) $easeOutCubic;
		
		&.out {
			animation: notification-out-left calc(var(--speed) / 2) $easeInCubic forwards;
		}
	}

	.notifications-list .notification {
		transition: transform var(--speed);
	}
}

.debug-notification {
	position: fixed;
	bottom: 0;
	left: 0;
	z-index: 10;
}

.notification {
	@extend %base_button;
	pointer-events: all;

	.content-wrapper {
		background: var(--color-background-primary);
		display: flex;
		text-align: left;
		padding: vw(15) vw(15) vw(15) vw(60);
		align-items: center;
	}

	.title {
		font-family: var(--font-family-title);
		font-weight: bold;
		font-size: vw(24);
		line-height: vw(25);
		text-transform: uppercase;
		color: #FFFFFF;
		margin-bottom: vw(4);
	}

	.description {
		display: flex;
		align-items: center;
		font-family: var(--font-family-text);
		font-weight: 700;
		font-size: vw(12);
		line-height: vw(20);
		text-transform: uppercase;
		color: var(--color-background-secondary);

		.checkmark-icon {
			width: vw(25);
			height: auto;
			--color: var(--color-background-secondary);
			margin-right: vw(10);
		}
	}

	.icon {
		width: vw(100);
		height: vw(100);
		display: flex;
		align-items: center;
		justify-content: center;
		border-radius: 100%;
		background: var(--color-accent-primary);
		font-family: var(--font-family-title);
		font-weight: bold;
		font-size: vw(48);
		line-height: 1;
		letter-spacing: -0.022em;
		text-transform: uppercase;
		color: #FFFFFF;
		padding-top: 0.2em;
		margin-left: vw(60);
	}

	&.no-icon .content-wrapper {
		padding-right: vw(60);
	}
}

@keyframes notification-in-left {
	0% {
		transform: translate(110%, 0);
	}
	100% {
		transform: translate(0, 0);
	}
}

@keyframes notification-out-left {
	0% {
		transform: translate(0, 0);
	}
	50% {
		transform: translate(110%, 0);
		height: 1.4em;
	}
	100% {
		height: 0;
		transform: translate(110%, 0);
	}
}
