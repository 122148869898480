.page.quiz {
	padding: vw(20);
	position: relative;

	.in-transition {
		pointer-events: none;
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		z-index: 2;
		object-fit: cover;
	}

	.page-content {
		position: relative;
		z-index: 1;
	}

	.thumbnail {
		border-radius: vw(20);
		overflow: hidden;
		margin-bottom: vw(27);
	}

	.thumbnail + .question-ctn {
		margin-top: 0;
		margin-bottom: vw(20);
	}

	.question-ctn {
		display: flex;
		padding: 0 vw(34);
		gap: vw(20);
		align-items: flex-start;
		margin-top: vw(109);
		margin-bottom: vw(60);

		.countdown {
			flex: 0 0 auto;
		}

		.content {
			flex: 1 1 100%;
		}

		.sponsor {
			display: flex;
			align-items: center;
			gap: vw(12);
			font-weight: 500;
			font-size: vw(20);
			line-height: 130%;
			margin-bottom: vw(16);

			img {
				max-width: vw(120);
			}
		}

		.question {
			font-family: var(--font-family-title);
			font-weight: bold;
			font-size: vw(40);
			line-height: 110%;
			letter-spacing: -0.011em;
			color: white;
			margin-bottom: vw(18);
		}
	}

	.choices {
		display: flex;
		flex-direction: column;
		gap: vw(20);
	}

	.choice {
		position: relative;
		display: flex;
		align-items: center;
		--spacing: #{vw(8)};
		z-index: 1;

		.letter {
			outline: none;
			border: none;
			width: vw(90);
			height: vw(90);
			display: flex;
			align-items: center;
			justify-content: center;
			text-align: center;
			background: rgba(black, 0.2);
			font-weight: 700;
			font-size: vw(36);
			text-transform: uppercase;
			margin-right: vw(26);
			color: rgba(255, 255, 255, 0.5);

			opacity: 1;

			transition: opacity 0.3s ease;
			z-index: 2;
		}

		.label {
			font-weight: 700;
			font-size: vw(32);
			line-height: 110%;
			z-index: 2;
		}

		.icon {
			position: absolute;
			top: 0;
			right: 0;
			height: 100%;
			display: grid;
			align-items: center;
			padding: 0 vw(35);
			pointer-events: none;
			z-index: 2;

			.close-icon,
			.checkmark-icon {
				width: vw(40);
				opacity: 0;
				transition: opacity 0.3s ease;
				grid-row: 1;
				grid-column: 1;
			}
		}

		.statistics {
			position: absolute;
			top: vw(8);
			left: vw(8);
			width: calc(100% - vw(16));
			height: calc(100% - vw(16));
			display: flex;
			align-items: center;
			white-space: nowrap;

			opacity: 0;
			transition: opacity 0.3s ease;

			font-family: var(--font-family-title);
			font-weight: bold;
			font-size: vw(32);
			line-height: 110%;
			text-transform: uppercase;
			z-index: 1;
			padding: 0 vw(13);

			.bar {
				position: absolute;
				top: 0;
				left: 0;
				width: calc(var(--percent) * 1%);
				height: 100%;
				border: none;
				background: rgba(black, 0.2);
				z-index: -1;
			}
		}

		&.accent {
			.letter {
				background: rgba(white, 0.2);
				color: white;
			}

			.statistics {
				.bar {
					background: rgba(white, 0.2);
				}
			}
		}

		&:not(.accent) {
			cursor: pointer;
		}

		&.wrong {
			.close-icon {
				opacity: 1;
			}
		}

		&.good {
			.checkmark-icon {
				opacity: 1;
			}
		}
	}

	.btn-ctn {
		margin-top: vw(34);
		display: flex;
		justify-content: center;
	}

	&.answered {
		.choices {
			pointer-events: none;
		}
	}

	&.survey.show-stats {
		.letter {
			opacity: 0;
		}

		.statistics {
			opacity: 1;
		}
	}

	&.theme-orange {
		.countdown {
			.circle-progress {
				--stroke-progress-color: white;
			}
		}
	}

	&.enter,
	&.appear {
		.page-content {
			opacity: 0;
		}

		&-active {
			.page-content {
				opacity: 1;
				transition: opacity 0s $easeOutCubic 0.6s;
			}
		}
	}
}

.quiz-tutorial {
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background: var(--color-overlay-background);
	z-index: 3;

	display: flex;
	flex-direction: column;
	align-items: center;
	text-align: center;
	padding: vw(80) vw(45) vw(45) vw(45);

	img {
		display: block;
		border-radius: vw(20);
		filter: drop-shadow(0px vw(4) vw(4) rgba(0, 0, 0, 0.25));
		margin-bottom: vw(57);
	}

	font-family: var(--font-family-title);
	font-weight: bold;

	.title {
		font-size: vw(72);
		line-height: 100%;
		text-align: center;
		letter-spacing: -0.011em;
		text-transform: uppercase;
		margin-bottom: vw(50);
	}

	.text {
		font-size: vw(32);
		line-height: 110%;
		text-align: center;
		letter-spacing: -0.011em;
		margin-bottom: vw(71);
	}

	&.enter,
	&.appear {
		img,
		.title,
		.text,
		.button {
			opacity: 0;
			transform: translate(0, vw(50));
		}

		&-active {
			img,
			.title,
			.text,
			.button {
				opacity: 1;
				transform: translate(0, 0);
				transition: opacity 0.6s $easeOutCubic var(--delay, 0s), transform 0.6s $easeOutCubic var(--delay, 0s);
			}
		}

		img { --delay: 0s }
		.title { --delay: 0.1s }
		.text { --delay: 0.2s }
		.button { --delay: 0.3s }
	}
}

.page.no-quiz {
	padding: vw(20) vw(60);
	z-index: 1;

	.title {
		margin-top: vw(500);
		font-family: var(--font-family-title);
		font-weight: bold;
		font-size: vw(60);
		line-height: 1;
		letter-spacing: -0.011em;
		color: white;
		margin-bottom: vw(20);
	}

	.text {
		font-weight: bold;
		font-size: vw(32);
		line-height: 100%;
		color: white;
	}

	&.enter,
	&.appear {
		.title,
		.text {
			opacity: 0;
			transform: translate(0, vw(50));
		}

		&-active {
			.title,
			.text {
				opacity: 1;
				transform: translate(0, 0);
				transition: opacity 0.6s $easeOutCubic var(--delay, 0s), transform 0.6s $easeOutCubic var(--delay, 0s);
			}
		}

		.title { --delay: 0.1s }
		.text { --delay: 0.2s }
	}
}

.quiz-bg {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	overflow: hidden;
	z-index: -1;

	background: var(--color-bg);

	.lightning-bg-icon {
		position: absolute;

		&.l-1 {
			top: vw(-695);
			left: vw(-1140);
			width: vw(1507);
			--foreground-color: var(--color-foreground);
			--background-color: transparent;
		}

		&.l-2 {
			top: vw(62);
			left: vw(194);
			width: vw(1507);
			--foreground-color: white;
			--background-color: transparent;
		}

		&.l-3 {
			top: vw(82);
			left: vw(214);
			width: vw(1507);
			--foreground-color: var(--color-foreground);
			--background-color: transparent;
		}
	}

	&,
	&.theme-beige {
		--color-bg: var(--color-accent-secondary);
		--color-foreground: var(--color-background-secondary);
	}

	&.theme-yellow {
		--color-bg: var(--color-background-primary);
		--color-foreground: #FFCC29;
	}

	&.theme-orange {
		--color-bg: var(--color-background-primary);
		--color-foreground: #FF4E02;
	}

	&.enter,
	&.appear {
		.l-1 {
			transform: translate(-100%, 0);
		}

		.l-2,
		.l-3 {
			transform: translate(100%, 0);
		}

		&-active {
			.l-1,
			.l-2,
			.l-3 {
				transform: translate(0, 0);
				transition: transform var(--timing, 0.6s) $easeOutCubic var(--delay, 0s);
			}
		}
	}
}