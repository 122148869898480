.page.missions {
	position: relative;
	padding-top: vw(90);

	&.tutorial {
		overflow: visible;
	}

	.lightning-bg-icon {
		position: fixed;
		top: 0;
		left: 0;
		z-index: -1;
		width: vw(1227);
		height: auto;
		top: vw(-77);
		left: vw(-42);
	}

	.ctn {
		position: relative;
		--spacing: #{vw(12)};
		
		.qr-scan {
			position: absolute;
			z-index: 2;
			top: 0;
			right: vw(27);
			pointer-events: none;

			.move {
				pointer-events: all;
				display: flex;
				align-items: center;
				transform: translate(0, -50%);

				color: var(--color-text-primary);
				font-family: var(--font-family-title);
				font-size: vw(30);
				font-weight: bold;
				line-height: 116%;
				letter-spacing: -0.022em;
				text-transform: uppercase;

				.qr-code {
					padding-right: var(--spacing);
					margin-right: var(--spacing);
					border-right: 1px solid rgba(#000, 0.3);
				}
	
				.scan-icon {
					width: vw(63);
					height: auto;
				}
			}
		}

		.top {
			--spacing: #{vw(21)};
			text-align: center;
			box-shadow: vw(0) vw(8) vw(8.3) rgba(0, 0, 0, 0.3);
			margin-bottom: vw(33);
			border: vw(1) solid rgba(black, 0.2);
			padding-top: vw(40);

			.mission-icon {
				width: vw(75);
				height: auto;
			}

			.title {
				font-family: var(--font-family-title);
				font-weight: bold;
				font-size: vw(48);
				line-height: 89%;
				text-transform: uppercase;
	
				color: var(--color-background-secondary);
				margin-top: vw(12);
				margin-bottom: vw(35);
			}
		}

		.sections {
			display: flex;
			flex-direction: column;
			gap: vw(29);
			padding: 0 vw(12) vw(12);
		}

		.section {
			.section-title {
				font-family: var(--font-family-title);
				font-weight: bold;
				font-size: vw(24);
				line-height: 89%;
				text-transform: uppercase;
				margin-bottom: vw(8);
				padding: 0 vw(8);

				display: flex;
				align-items: center;

				.chevron-down {
					position: relative;
					flex: 0 0 auto;
					width: vw(24);
					height: auto;
					margin-left: auto;
					transition: transform 0.3s ease;

					&.opened {
						transform: rotate(180deg);
					}
				}
			}

			.section-description,
			.section-description a {
				font-size: vw(20);
				line-height: 110%;
				font-family: var(--font-family-title);
				font-weight: bold;
				// text-transform: uppercase;
				color: var(--color-background-secondary);
				text-decoration: none;

				a {
					font-weight: 900;
				}
			}

			.children {
				overflow: hidden;
				transition: max-height 0.6s ease, padding 0.6s ease;
			}

			.section-missions {
				display: flex;
				flex-direction: column;
				gap: vw(20);
				padding: vw(8);
			}
		}
	}

	.mission {
		display: flex;
		align-items: center;
		gap: vw(18);
		min-height: vw(100);

		--spacing: #{vw(9)};

		.checkbox {
			--spacing: 0;
			width: vw(40);
			height: vw(40);
			flex: 0 0 auto;
			border: none;
			margin-left: vw(16);
			display: flex;
			align-items: center;
			justify-content: center;

			background-color: rgba(black, 0.3);

			.lightning-icon {
				width: vw(26);
				--color-foreground: white;
				--color-background: transparent;
				opacity: 0;
				transition: opacity 0.3s ease;
			}
		}

		&.accent {
			.checkbox {
				background-color: rgba(white, 0.3);

				.lightning-icon {
					opacity: 1;
				}
			}
		}

		.content {
			flex: 1 1 100%;
			display: flex;
			flex-direction: column;
			justify-content: center;
			align-self: stretch;
			border-right: 1px solid rgba(black, 0.3);
			padding-right: vw(18);

			.name {
				font-weight: 700;
				font-size: vw(20);
				line-height: 110%;
				/* or 27px */
				letter-spacing: -0.011em;
				text-transform: uppercase;
				margin-bottom: vw(4);
			}

			.threshold,
			.description {
				font-weight: 500;
				font-size: vw(18);
				line-height: 110%;
				letter-spacing: -0.011em;

				color: var(--color-background-secondary);
			}
		}

		.rewards {
			width: vw(111);
			flex: 0 0 auto;
			font-weight: 700;
			font-size: vw(16);
			line-height: 140%;
			letter-spacing: 0.02em;
			text-transform: uppercase;

			.extra {
				font-size: 90%;
			}
		}
	}

	.tutorial-text {
		position: absolute;
		top: vw(60);
		left: vw(85);
		z-index: 11;

		.arrow {
			width: vw(47);
			height: vw(129);
			transform: translate(0, 100%) scale(1, -1);
		}

		.text {
			position: absolute;
			top: vw(129);
			left: vw(71);
			width: vw(440);
			font-family: var(--font-family-title);
			font-weight: bold;
			font-size: vw(32);
			line-height: 110%;
			letter-spacing: -0.011em;
			transform: translate(0, -50%);
		}
	}

	.section.empty {
		.section-title {
			background: rgba(black, 0.1);
		}

		.box.mission {
			background: rgba(black, 0.1);
			border: none;
			outline: none;
		}
	}

	&.tutorial {
		.overlay {
			position: fixed;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
			background: var(--color-overlay-background);
			z-index: 3;
		}

		.box.mission.tutorial-show {
			z-index: 10;
		}
	}
	
	&.enter,
	&.appear {
		> .lightning-bg-icon {
			transform: translate(100%, 0);
		}

		.top,
		.qr-scan,
		.tutorial-text,
		&:not(.tutorial) .ctn,
		&:not(.tutorial) .section {
			opacity: 0;
			transform: translate(0, vw(50));
		}

		&.tutorial .section .heading,
		&.tutorial .section .box,
		&.tutorial:after {
			opacity: 0;
		}

		&-active {
			> .lightning-bg-icon {
				transform: translate(0, 0);
				transition: transform 0.9s $easeOutCubic var(--delay, 0s);
			}

			.top,
			.qr-scan,
			.tutorial-text,
			&:not(.tutorial) .ctn,
			&:not(.tutorial) .section {
				opacity: 1;
				transform: translate(0, 0);
				transition: opacity 0.6s $easeOutCubic var(--delay, 0s), transform 0.6s $easeOutCubic var(--delay, 0s);
			}
			
			&.tutorial .section .heading,
			&.tutorial .section .box,
			&.tutorial:after {
				opacity: 1;
				transition: opacity 0.6s $easeOutCubic var(--delay, 0s);
			}
		}

		.ctn { --delay: 0s }
		.top { --delay: 0.1s }
		.qr-scan { --delay: 0.2s }

		&:not(.tutorial) .sections {
			@for $i from 1 through 20 {
				.section:nth-child(#{$i}) { --delay: #{0.05 * $i + 0.25}s; }
			}
		}

		&.tutorial .sections {
			@for $i from 1 through 20 {
				.section:nth-child(#{$i}) { --delay: #{0.05 * $i + 0.6}s; }
			}
		}

		>.lightning-bg-icon { --delay: 0.3s }
		&.tutorial:after { --delay: 0s }
		.tutorial-text { --delay: 1.2s }
	}
}